<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Tulis Baru
            </div>
          </template>

          <v-form
            v-model="disable_state"
            @submit.prevent="setPost"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="title"
                    :rules="titleRules"
                    label="Judul"
                    class="purple-input"
                    prepend-icon="mdi-pencil-plus"
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-select
                    v-model="status"
                    :items="status_items"
                    label="Pilih Status"
                    prepend-icon="mdi-format-list-checks"
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-select
                    v-model="category_id"
                    :items="category"
                    :rules="categoryRules"
                    class="purple-input"
                    prepend-icon="mdi-format-list-checks"
                    label="Pilih Kategori"
                    item-text="name"
                    item-value="id"
                  >
                    <!-- Template for render data when the select is expanded -->
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <!-- Divider and Header-->
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <!-- Normal item -->
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name" />
                          <v-list-item-subtitle
                            v-html="
                              data.item.parent ? data.item.parent.name : ''
                            "
                          />
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-file-input
                    v-model="file1"
                    :state="Boolean(file1)"
                    :loading="upload_state"
                    :disabled="disable_state"
                    counter
                    show-size
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    placeholder="Unggah Gambar Thumbnail"
                    @change="uploadImage"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="post"
                    :rules="postRules"
                    class="purple-input"
                    label="Artikel Teks"
                    prepend-icon="mdi-square-edit-outline"
                    auto-grow
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    :disabled="!disable_state"
                    @click="setPost"
                  >
                    POSTING
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')

  export default {
    name: 'CreateNews',
    data () {
      return {
        title: '',
        thumbnail_id: 4,
        image_id: 4,
        post: '',
        featured: 0,
        community_id: 1,
        sorting: 1,
        status: '',
        status_items: ['publish', 'draft', 'review'],
        polling_id: '',
        polling: {
          meta: {},
          data: {},
        },
        type: 'news',
        file1: null,
        upload_state: false,
        disable_state: false,
        category_id: null,
        selected: null,
        category: [
          {
            id: 1,
            name: 'Komunitas',
            type: 'news',
            status: 'publish',
            deleted_at: null,
            created_at: '2020-11-28T08:11:52.000000Z',
            updated_at: '2020-11-28T08:11:52.000000Z',
            community_id: 1,
            category_id: null,
            parent: {
              id: 2,
              name: 'Terbaru',
              type: 'news',
              status: 'publish',
              deleted_at: null,
              created_at: '2020-11-28T08:12:05.000000Z',
              updated_at: '2020-11-28T08:12:05.000000Z',
              community_id: 1,
              category_id: 1,
            },
          },
        ],
        titleRules: [value => value !== '' || 'Judul wajib diisi'],
        categoryRules: [value => value !== '' || 'Kategori wajib diisi'],
        postRules: [
          value => value !== '' || 'post wajib diisi',
          value => value.length >= 4 || 'password minimal 4 carakter',
        ],
      }
    },
    mounted () {
      this.getCategories()
    // this.getPolling()
    },
    methods: {
      // setPolling (data) {
      //   this.polling = data
      //   console.log(this.polling)
      // },
      // getPolling () {
      //   axios
      //     .get('/v1/polling?sort=-id&entities=user,community,options')
      //     .then(response => {
      //       this.setPolling(response.data)
      //     })
      // },
      setPost () {
        this.disable_state = true
        axios
          .post('/v1/post/news', {
            title: this.title,
            thumbnail_id: this.image_id,
            image_id: this.image_id,
            post: this.post,
            featured: 0,
            community_id: this.$store.state.user.community_id,
            sorting: 1,
            status: this.status,
            type: 'news',
            category_id: this.category_id,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.disable_state = false
              this.image_id = response.data.data.id
              this.$toast.success('Berhasil Membuat News', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
              this.$router.push({ name: 'ListNews' })
            } else {
              console.log(response.data.meta.message)
            }
          // console.log("image upload response > ", response.data);
          })
      },
      upload (event) {
        this.upload_state = true
        console.log(event.target.files[0])
      },
      uploadImage (event) {
        if (this.file1 === null) {
          this.upload_state = false
          this.disable_state = false
          return 1
        }
        const URL = '/v1/media'
        this.upload_state = true
        const data = new FormData()
        data.append(
          'path',
          'image/news/community/' + this.$store.state.user.community_id,
        )
        data.append('file', this.file1)
        const config = {
          header: {
            'Content-Type': 'image/png',
          },
        }

        axios.post(URL, data, config).then(response => {
          if (response.data.meta.status) {
            this.upload_state = false
            this.disable_state = false
            this.image_id = response.data.data.id
            this.$toast.success('Berhasil Menunggah Gambar', {
              type: 'success',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          } else {
            this.upload_state = false
            this.disable_state = true
            this.$toast.error('Maksimal ukuran gambar 1MB', {
              type: 'error',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          }
        })
      },
      getCategories () {
        axios
          .get('/v1/category?only_parent=0&sort=id&entities=parent&type=news')
          .then(response => {
            if (response.data.meta.status) {
              this.category = response.data.data
            } else {
              console.log(response.data.meta.message)
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
